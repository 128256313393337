import React, {useContext, useState} from 'react';
// import {ReactSVG} from 'react-svg';
import './DataTableNavigationBar.scss';
import DataTableContext from './DataTableContext';

const DataTableNavigationBar = props => {
    const {subPhases, onSelectedSubphase, clickedPhase} = props;
    const [hoveredColumnName, setHoveredColumnName] = useState(null);
    const {columnNames, onColumnFocus, selectedColumn} = useContext(DataTableContext);
    const formatColumnNames = subPhases || columnNames;
    const iconName = columnName => (subPhases ? `${process.env.PUBLIC_URL}/assets/icons/${columnName.toLowerCase()}.svg` : `${process.env.PUBLIC_URL}/assets/icons/navigation-${columnName.toLowerCase()}-${
        selectedColumn === columnName
            ? 'selected'
            : hoveredColumnName === columnName
                ? 'hover'
                : 'idle'
    }.svg`);

    return (
        <div className="sski-c-bottom-navigation-bar">
            {formatColumnNames.map((columnName, index) => (
                <img
                    key={columnName}
                    className={`${subPhases ? (hoveredColumnName === columnName && clickedPhase) ? `sski-c-bottom-navigation-bar__icon-selected-${columnName.slice(0, 1).toLowerCase()}` : 'is-clickable' : index % 2 === 0 ? 'mt-5' : 'mb-5'}`}
                    alt={columnName}
                    src={iconName(columnName)}
                    onClick={() => {
                        if (onColumnFocus) { onColumnFocus(index, columnName); }
                        if (onSelectedSubphase) { onSelectedSubphase(columnName.toLowerCase()); }
                    }}
                    onMouseEnter={() => setHoveredColumnName(columnName)}
                    onMouseLeave={() => setHoveredColumnName(null)}
                />
            ))}
        </div>
    );
};

export default DataTableNavigationBar;
