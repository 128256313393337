import React, {useContext, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {BlocksRenderer} from '@strapi/blocks-react-renderer';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {linkTypes, subPhaseTypes, topicTypes} from '@swiss-ski/sski-entity-types';
import './CategoriesScreen.scss';
import DataSet from './ui-elements/DataSet';
import TopNavigationBar from './ui-elements/TopNavigationBar';
import * as applicationActionTypes from '../application/applicationActionTypes';
import DataTableProvider from './ui-elements/DataTableProvider';
import DataRow from './ui-elements/DataRow';
import DataCell from './ui-elements/DataCell';
import {CATEGORY_ID} from '../utils/ftemConstants';
import {MobileDevice} from '../index';
import PhaseLoader from '../dashboard/ui-elements/PhaseLoader';

const CategoriesScreen = props => {
    const {translate} = useTranslate();
    const {selectedCategoriesTableContent, initiateLinkViewModalFlow, history} = props;
    const {location} = history ?? {};
    const [queryParams, setQueryParams] = useState(new URLSearchParams(location?.search));
    const [shouldRerender, setShouldRerender] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const {isMobile} = useContext(MobileDevice);
    const formattedSelectedCategoriesTableContent = isLoading ? {} : selectedCategoriesTableContent;

    const subPhases = useMemo(() => {
        const titles = {};
        Object.entries(subPhaseTypes).forEach(([key, value]) => {
            titles[key] = Object.values(value).map(subPhase => ({
                id: subPhase,
                name: translate(`categories_screen.sub_phase_title.${subPhase.toLowerCase()}`),
            }));
        });
        return titles;
    }, [translate]);

    useEffect(() => {
        if (!location?.search) return;
        const newQueryParams = new URLSearchParams(location.search);
        const newCategoryIds = newQueryParams.getAll(CATEGORY_ID);
        const oldCategoryIds = queryParams.getAll(CATEGORY_ID);

        if (newCategoryIds.length === oldCategoryIds.length) {
            setShouldRerender(false);
            return;
        }
        setShouldRerender(true);
        setQueryParams(newQueryParams);
    }, [location?.search, queryParams]);

    useEffect(() => {
        setIsLoading(false);
    }, [selectedCategoriesTableContent]);

    return (
        <div className="sski-c-categories-screen__background">
            <TopNavigationBar setIsLoading={setIsLoading} />
            <DataTableProvider
                columnNames={Object.values(subPhaseTypes).map(subPhaseType => Object.values(subPhaseType)).flat()}
            >
                {selectedCategoriesTableContent && Object.keys(formattedSelectedCategoriesTableContent).map(categoryId => {
                    return !!selectedCategoriesTableContent[categoryId]
                    && typeof selectedCategoriesTableContent[categoryId] === 'object'
                    && Object.keys(selectedCategoriesTableContent[categoryId].subCategories).length > 0
                        ? (
                            <DataSet
                                key={categoryId}
                                dataSetTitle={selectedCategoriesTableContent[categoryId].name}
                                headersContent={subPhases}
                                haveToRerender={shouldRerender}
                            >
                                {Object.keys(selectedCategoriesTableContent[categoryId].subCategories)
                                    .map((rowKey, rowKeyIndex) => {
                                        const rowsContent = selectedCategoriesTableContent[categoryId].subCategories;
                                        const isLastRow = Object.keys(rowsContent).length - 1 === rowKeyIndex;
                                        const isOnlyRow = Object.keys(rowsContent).length === 1;
                                        const cellTitle = rowsContent[rowKey].name;

                                        return (
                                            <DataRow key={rowKey}>
                                                {Object.entries(rowsContent[rowKey])
                                                    .filter(([key]) => key !== 'name')
                                                    .map(([key, rowContentSet]) => {
                                                        if (!rowContentSet) return null;

                                                        return rowContentSet.map((rowContent, index) => {
                                                            return (
                                                                <DataCell
                                                                    key={`${rowKey}-${key}-${index}`}
                                                                    colorType={key.toLowerCase()}
                                                                    position={isLastRow
                                                                        ? rowContentSet.length === 1
                                                                            ? 'bottom'
                                                                            : index === 0
                                                                                ? 'bottom-left'
                                                                                : index === rowContentSet.length - 1
                                                                                    ? 'bottom-right'
                                                                                    : ''
                                                                        : ''}
                                                                    isRightEndCell={rowContentSet.length > 1
                                                                        && index === rowContentSet.length - 1}
                                                                    isMiddleRowCell={!isLastRow}
                                                                    isContentCentered={categoryId === topicTypes.AGE_CATEGORY.toLowerCase()} // eslint-disable-line max-len
                                                                    isContentBold={categoryId === topicTypes.AGE_CATEGORY.toLowerCase()} // eslint-disable-line max-len
                                                                    columnName={Object.values(subPhaseTypes[key])[index]} // eslint-disable-line max-len
                                                                >
                                                                    {!isOnlyRow && (
                                                                        <p className="mb-2">
                                                                            {cellTitle}
                                                                        </p>
                                                                    )}
                                                                    <div
                                                                        className={`sski-c-data-set__cell-content is-flex is-flex-direction-column is-align-items-center is-justify-content-space-between ${
                                                                            !isOnlyRow && rowContent?.links?.length > 0
                                                                                ? 'sski-c-data-set__cell-content--padding-bottom'
                                                                                : ''
                                                                        }`}
                                                                    >
                                                                        {typeof rowContent?.text === 'object'
                                                                            ? (
                                                                                <div
                                                                                    className="sski-c-data-set__cell-content-text sski-c-data-set__cell-content--font-medium"
                                                                                >
                                                                                    <BlocksRenderer
                                                                                        content={rowContent.text}
                                                                                    />
                                                                                </div>
                                                                            )
                                                                            : rowContent?.text}
                                                                        {rowContent?.links?.length > 0 && (
                                                                            <div
                                                                                className="is-flex is-justify-content-center"
                                                                            >
                                                                                <button
                                                                                    type="button"
                                                                                    className={`sski-c-data-set__link-button sski-c-data-set__link-button--${key.toLowerCase()} ${
                                                                                        rowContent.links[0]?.type === linkTypes.PRESENTATION.toLowerCase() && 'p-2'} button`}
                                                                                    onClick={() => (
                                                                                        rowContent.links[0]?.type !== linkTypes.OTHER.toLowerCase() // eslint-disable-line max-len
                                                                                            ? !isMobile ? initiateLinkViewModalFlow({
                                                                                                linkColorType: key,
                                                                                                linkId: rowContent.links[0]?.id, // eslint-disable-line max-len
                                                                                            }) : window.open(rowContent.links[0]?.links, '_blank')
                                                                                            : window.open(rowContent.links[0]?.links, '_blank')
                                                                                    )}
                                                                                >
                                                                                    <img
                                                                                        alt={rowContent.links[0]?.id}
                                                                                        className="sski-c-data-set__link-icon"
                                                                                        src={`${process.env.PUBLIC_URL}/assets/icons/link-${rowContent.links[0]?.type?.toLowerCase() ?? linkTypes.PRESENTATION.toLowerCase()}.svg`}
                                                                                    />
                                                                                </button>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </DataCell>
                                                            );
                                                        });
                                                    })}
                                            </DataRow>
                                        );
                                    })}
                            </DataSet>
                        )
                        : null;
                })}
                {isLoading
                    && (
                    <div className="column">
                        <PhaseLoader full={true} />
                        <PhaseLoader full={true} />
                    </div>
                    )
                }
            </DataTableProvider>
        </div>
    );
};

CategoriesScreen.propTypes = {
    selectedCategoriesTableContent: PropTypes.object,
    initiateLinkViewModalFlow: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
};

CategoriesScreen.defaultProps = {
    selectedCategoriesTableContent: null,
};

const mapStateToProps = state => {
    return {
        selectedCategoriesTableContent: state.categories.selectedCategoriesTableContent,
    };
};


const mapDispatchToProps = dispatch => {
    return {
        initiateLinkViewModalFlow: payload => dispatch({
            type: applicationActionTypes.OPEN_LINK_VIEW_MODAL,
            payload,
        }),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CategoriesScreen));
